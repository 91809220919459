<template>
  <section class="management-tenants">
    <div class="vx-row">
      <div class="vx-col w-full vx-dl-sm">
        <vx-data-list
          ref="vxDataList"
          pagination
          :data="tenants"
          :columns="columns"
          :search="true"
          @filter="filter"
          :config="{
            selectable: false
          }"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import Paging from '@/entity/Paging';
import PlanThumbnail from './components/PlanThumbnail';
export default {
  data() {
    return {
      columns: [
        {
          label: 'Company',
          renderer: tr => {
            if (!tr.companyName) return 'N/a';
            return `${tr.companyName}`;
          }
        },
        {
          label: 'Name',
          renderer: tr => {
            if (!tr.firstName && !tr.lastName) return 'N/a';
            return `${tr.firstName} ${tr.lastName}`;
          }
        },
        {
          label: 'Plan',
          field: 'plan',
          classes: 'text-xs truncate max-w-26',
          component: PlanThumbnail
        },
        {
          label: 'Total Captures',
          field: 'totalCaptures'
        }
      ]
    };
  },
  computed: {
    ...mapState('management', ['tenants'])
  },
  methods: {
    async fetch(params = {}) {
      const { page, size } = new Paging();
      await this.$store.dispatch('management/tenants', {
        params: {
          page,
          size,
          ...params
        }
      });
    },
    filter(params) {
      this.fetch(params);
    }
  },
  async created() {
    await this.fetch();
  },
  mounted() {}
};
</script>
