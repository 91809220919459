<template>
  <div class="summary-check-block py-1" v-if="data">
    <div v-if="!data.plan">N/a</div>
    <div v-else>
      <p class="font-semibold">{{ data.plan.name }}</p>
      <p class="text-xs" v-if="data.plan.description && data.plan.description.summary">{{ data.plan.description.summary }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data', 'config'],
  mounted() {}
};
</script>
